import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../public/assets/css/style.min.css';
import '../public/assets/css/fonts.min.css';
import useToast from 'vue-toast-notification';
import MultiSelect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

const app = createApp({
    render () { return h(App); },
});

app.component("MultiSelect", MultiSelect);
app.component('Toast', useToast);
app.use(router)
app.mount('#app')
app.config.devtools = true;
